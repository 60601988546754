import React, {memo} from 'react';
import NameScribble from "../img/name.png";

function Name(props: {onClick: () => any}) {
  return (
    <div id="title-name" onClick={props.onClick}>
      <img src={NameScribble} alt="Kai Nylund"></img>
    </div>
  );
}

export default memo(Name);