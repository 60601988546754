import React, {memo, useState} from 'react';
import Headshot from "../img/line-headshot-posterized.png";
import { animated, useSpring } from 'react-spring';
import Socials from './Socials';

function AboutPage() {
  const sp1 = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: { opacity: 0, transform: "translateY(-20px)" },
  });

  return (
    <animated.div id="about-page" aria-label="About me" style={sp1}>
      <img src={Headshot} alt="Line-drawing headshot of kai nylund, a white man wearing headphones and a t-shirt."></img>
      <article>
        <h1>About me</h1>
        <p>
          Hi! I'm currently looking for visualization and NLP PhD programs.
          Previously I was a master's student in computer science at the University of Washington,
          and a member of the <a href="https://noahs-ark.github.io/" target="_blank" rel="noreferrer">ARK NLP group</a>.
          Right now I'm most interested in the intersection of visualization and topics like <a href="https://arxiv.org/pdf/2206.11684.pdf"
          target="_blank" rel="noreferrer">LM stereotyping</a> and <a href="https://arxiv.org/pdf/2111.07408.pdf"
          target="_blank" rel="noreferrer">distribution shifts</a>.
          {/*I also previously studied <a href="https://chid.washington.edu/" target="_blank" rel="noreferrer">CHID</a>.*/}
        </p>
        <p>
          I also like <a href="https://seattleboulderingproject.com/fremont"
          target="_blank" rel="noreferrer">rock climbing</a>, making art, and plants.
        </p>
        <h2>Huge thank you to my past mentors:</h2>
        <ul>
          <li><a href="https://suchin.io/" target="_blank" rel="noreferrer">Suchin Gururangan</a></li>
          <li><a href="https://people.csail.mit.edu/cloudygoose/" target="_blank" rel="noreferrer">Tianxing He</a></li>
          <li><a href="https://danbirman.com/" target="_blank" rel="noreferrer">Dan Birman</a></li>
          <li><a href="https://sites.google.com/view/paige-helms" target="_blank" rel="noreferrer">Paige Helms</a></li>
        </ul>
      </article>
      <Socials/>
    </animated.div>
  );
}

export default memo(AboutPage);