import AboutPage from "./AboutPage";
import '../styles/App.css';
import Name from './Name';
import NavIcon from './NavIcon';
import AboutImg from "../img/about-icon.png";
import ClassesImg from "../img/classes-icon.png";
import ProjectsImg from "../img/projects-icon.png";
import ProjectsPage from "./ProjectsPage";
import ClassesPage from "./ClassesPage";
import { memo, useEffect, useState } from "react";
import LandingPage from "./LandingPage";
import Loading from "./Loading";

function App() {
  const [curPage, setCurPage] = useState("loading");

  useEffect(() => {
    setTimeout(() => {
      setCurPage("start");
    }, 2000 + 1000 * Math.random());
  }, []);

  if (curPage === "loading") {
    return <Loading/>;
  } else if (curPage === "start") {
    return <LandingPage aboutOnClick={() => setCurPage("about")}
                        projectsOnClick={() => setCurPage("projects")}
                        classesOnClick={() => setCurPage("classes")}/>;
  }
  return (
    <div id="App">
      <header>
        <Name onClick={() => setCurPage("start")}/>
        <nav>
          <NavIcon iconImg={AboutImg}
                   onClick={() => setCurPage("about")}
                   imgDesc={"About section"}
                   selected={curPage === "about"}/>
          <NavIcon iconImg={ProjectsImg}
                   onClick={() => setCurPage("projects")}
                   imgDesc={"Projects section"}
                   selected={curPage === "projects"}/>
          <NavIcon iconImg={ClassesImg}
                   onClick={() => setCurPage("classes")}
                   imgDesc={"Classes section"}
                   selected={curPage === "classes"}/>
        </nav>
      </header>
      <main>
        {curPage === "about" && <AboutPage/>}
        {curPage === "projects" && <ProjectsPage/>}
        {curPage === "classes" && <ClassesPage/>}
      </main>
    </div>
  );
}

export default memo(App);
