import {memo} from 'react';
import Project from './Project';
import projectData from "../data/projects.json";
import { animated, useSpring } from 'react-spring';

export type ProjectType = {
  startDate: string
  endDate: string,
  link: string,
  name: string,
  org: string,
  role: string,
}

function ProjectsPage() {
  const sp1 = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: { opacity: 0, transform: "translateY(-20px)" },
  });

  const projects = [];
  console.log(projectData);

  for (let entry of projectData) {
    projects.push(
      <Project //img={""}
        name={entry.name}
        role={entry.role}
        org={entry.org}
        startDate={entry.startDate}
        endDate={entry.endDate}
        link={entry.link}
        key={"project-" + entry.name}/>
    );
  }

  return (
    <animated.div id="projects-page" aria-label="My projects" style={sp1}>
      <div id="projects-header">
        <h1>Name</h1>
        <h1>Role</h1>
        <h1>Organization</h1>
        <h1>Date</h1>
      </div>
      <div id="projects-row">
        {projects}
      </div>
    </animated.div>
  );
}

export default memo(ProjectsPage);