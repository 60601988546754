import { configureStore } from '@reduxjs/toolkit';

export const defaultState = {
  curPage: "loading"
}

function pageReducer(state = defaultState.curPage, action: {type: string, page: string}) {
  switch (action.type) {
    case "page/set":
      return action.page;
    default:
      return state
  }
}

export const store = configureStore({
  reducer: {
    curPage: pageReducer
  },
});
