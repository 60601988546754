import React, {memo} from 'react';
import LoadingSpinner from "../img/voronoi-spinner2.gif";//"../img/fractal-spinner.gif"
import AboutTab from "../img/about-tab.png";
import ProjectsTab from "../img/projects-tab.png";
import ClassesTab from "../img/classes-tab.png";
import Name from './Name';
import { animated, useSpring } from 'react-spring';

function StartPage(props: {aboutOnClick: any,
                           projectsOnClick: any,
                           classesOnClick: any}) {

  const spinnerFadeIn = useSpring({
    to: {opacity: 0.15},//, transform: "rotate(0deg) translateY(calc(-50% + 0.166 * 25rem))"},
    from: { opacity: 0},//, transform: "rotate(45deg) translateY(calc(-50% + 0.166 * 25rem))"},
    config: { duration: 1000 }
  });


  const sp1 = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: { opacity: 0, transform: "translateY(-20px)" },
    delay: 0
  });

  const sp2 = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: { opacity: 0, transform: "translateY(-20px)" },
    delay: 200
  });

  const sp3 = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: { opacity: 0, transform: "translateY(-20px)" },
    delay: 400
  });

  return (
    <div id="landing-page" aria-label="Landing page">
      <header>
        <Name onClick={null}/>
        <animated.img style={spinnerFadeIn} className="spinner" src={LoadingSpinner}
            alt={"A looping animated Voronoi diagram of a fractal at different steps"} width={400}></animated.img>
      </header>
      <nav>
        <button>
          <animated.img style={sp1} src={AboutTab} alt={"About section"}
                        onClick={props.aboutOnClick}></animated.img>
        </button>
        <button>
          <animated.img style={sp2} src={ProjectsTab} alt={"Projects section"}
                        onClick={props.projectsOnClick}></animated.img>
        </button>
        <button>
          <animated.img style={sp3} src={ClassesTab} alt={"Classes section"}
                        onClick={props.classesOnClick}></animated.img>
        </button>
      </nav>
    </div>
  );
}

export default memo(StartPage);