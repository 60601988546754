import React, {memo} from 'react';

function Project(props: {//img: any,
                         name: string,
                         org: string,
                         role: string,
                         startDate: string,
                         endDate: string,
                         link: string}) {

  return (
    <a className="project" href={props.link} target="_blank" rel="noreferrer" tabIndex={0}>
      {/*<img src={props.img} alt={"Image for " + props.name}></img>*/}
      <h2>{props.name}</h2>
      <p>{props.role}</p>
      <p>{props.org}</p>
      <p>{(props.startDate === props.endDate) ? props.startDate :
           props.startDate + " to " + props.endDate}</p>
    </a>
  );
}

export default memo(Project);