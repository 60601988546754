import {memo} from 'react';
import LoadingSpinner from "../img/loading-spinner.gif";

function Loading() {
  return (
    <div id="loading-screen">
      <img src={LoadingSpinner}
           alt="An animated fractal at different steps with the shape of a loading spinner"></img>
    </div>
  );
}

export default memo(Loading);