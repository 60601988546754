import React, {memo, useState} from 'react';

function NavIcon(props: {iconImg: any,
                         onClick: any,
                         imgDesc: string,
                         selected: boolean}) {
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <button>
      <img src={props.iconImg}
         alt={props.imgDesc}
         className={(props.selected) ? "selected-nav" : ""}
         onMouseEnter={() => setIsMouseOver(true)}
         onMouseLeave={() => setIsMouseOver(false)}
         onClick={props.onClick}>
      </img>
    </button>
  );
}

export default memo(NavIcon);