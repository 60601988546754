import React, {memo, useEffect, useState} from 'react';
import {loadClassData} from "../visualizations/app";
import classData from "../data/classes.json";
import classAbvToDept from "../data/classAbvToDept.json";
import { useSpring, animated } from 'react-spring';


function ClassesPage() {

  const sp1 = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: { opacity: 0, transform: "translateY(-20px)" },
  });

  useEffect(() => {
    loadClassData(classData, classAbvToDept);
  }, []);

  return (
    <div id="classes-container" aria-label="classes I've taken">
      <div id="class-selectors-container">
        <animated.div id="class-selectors" style={sp1}>
          <div id="class-force-select" aria-label="Change how classes are organized">
            <div>
              <p>Style</p>
              <span className="selected-class-style">major</span>
            </div>
            <ul className="nodisplay">
              <li>major</li>
              <li>number</li>
              <li>unsorted</li>
            </ul>
          </div>
          <div id="class-color-select">
            <div>
              <p>Color</p>
              <span className="selected-class-style">department</span>
            </div>
            <ul className="nodisplay" aria-label="Change how classes are colored">
              <li>department</li>
              <li>number</li>
              <li>credits</li>
              {/*<li>grade</li>*/}
            </ul>
          </div>
        </animated.div>
      </div>
      <div id="graph-container">
        <svg id="class-graph"></svg>
        <svg id="class-legend"></svg>
      </div>
    </div>
  );
}

export default ClassesPage;