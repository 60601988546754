  import * as d3 from 'd3';

  // Stores all class node and link data
  let nodes = [];
  let links = [];

  // Data about classes: all titles, departments, numbers, major categories, and min/max num
  let classTitles = [];
  let departments = new Set();
  let deptAbvToName = new Map();
  let numbers = [[], [], [], [], [], []];
  let minNum = Infinity;
  let maxNum = -1;
  let majorCategories = {"CS" : new Set(["CSE", "MATH", "PHYS"]),
                         "CHID" : new Set(["CHID", "ENGL", "GWSS", "ANTH", "C LIT", "SLAVIC"])}

  export async function loadClassData(classData, classAbvToName) {
    // Parse data from csv into node and link objects and update global lists
    for (let dept of classAbvToName) {
      deptAbvToName.set(dept.abv, dept.name);
    }

    if (nodes.length === 0) {
      addClassNodes(classData);
    }
    if (links.length === 0) {
      addClassLinks(classData);
    }
    updateClassGraph();
    updateClassGraphListeners();
    initTooltip();
  }

  function toggleChildList() {
    let childList = this.querySelector("ul");
    childList.classList.toggle("nodisplay");
  }

  function updateClassGraphListeners() {
    let forceSelect = id("class-force-select");
    let colorSelect = id("class-color-select");
    forceSelect.addEventListener("click", toggleChildList);
    colorSelect.addEventListener("click", toggleChildList);
    for (let option of forceSelect.querySelectorAll("li")) {
      option.addEventListener("click", updateForceMode);
    }
    for (let option of colorSelect.querySelectorAll("li")) {
      option.addEventListener("click", updateColorMode);
    }
    id("class-force-select").querySelector(".selected-class-style").innerHTML = forceMode;
    id("class-color-select").querySelector(".selected-class-style").innerHTML = colorMode;
  }

  // Update the coloring with the value from the dropdown and redraw the graph
  function updateColorMode() {
    colorMode = this.innerHTML;
    id("class-color-select").querySelector(".selected-class-style").innerHTML = colorMode;
    updateClassGraph();
  }

  // Update the force sorting with the value from the dropdown and redraw the graph
  function updateForceMode() {
    forceMode = this.innerHTML;
    id("class-force-select").querySelector(".selected-class-style").innerHTML = forceMode;
    updateClassGraph();
  }

  function addClassLinks(classesData) {
    for (let i = 0; i < classesData.length; i++) {
      let cl = classesData[i];
      let reqs = cl.prereqFor.split("&").filter(r => r !== "");
      for (let req of reqs) {
        let linkObj = Object.create({});
        linkObj.source = i;
        linkObj.target = classTitles.indexOf(req.replace(/\s/g,''));
        links.push(linkObj);
      }
    }
  }

  function addClassNodes(classesData) {
    for (let cl of classesData) {
      let classObj = Object.create({});
      // Parse csv fields
      let dept = cl.department;
      let numTitle = cl.number.trim();
      let num = parseInt(numTitle);
      let abv = (dept + numTitle).replace(/\s/g,'');
      // update global class data lists
      minNum = Math.min(minNum, num);
      maxNum = Math.max(maxNum, num);
      numbers[Math.floor(num / 100)].push(numTitle);
      departments.add(dept);
      classTitles.push(abv);
      // populate class object fields
      classObj.x = Math.random() * width;
      classObj.y = Math.random() * height;
      classObj.id = abv;
      classObj.department = dept;
      classObj.number = num;
      classObj.numTitle = numTitle;
      classObj.grade = cl.grade.trim();
      classObj.credits = parseInt(cl.credits.trim());
      classObj.title = cl.fullName;
      nodes.push(classObj);
    }
    for (let numArr of numbers) {
      numArr.sort();
    }
    departments = Array.from(departments);
  }

  /* --------------------------- D3 Plotting Functions --------------------------- */

  // Initial force and color sorting modes
  let forceMode = "major";
  let colorMode = "department";

  // True iff dragging a class node
  let isDragging = false;

  // default class graph width, height, and node radius
  let classRadius = 20;
  let width = 700;
  let height = 350;
  const margin = ({top: 100, right: 10, bottom: 30, left: 10});

  let style_by_mode = {"unsorted": {"r": 12, "h": 400, "w": 400},
                       "major":    {"r": 16, "h": 350, "w": 700},
                       "number":   {"r": 16, "h": 600, "w": 800}}

  // Tooltip and notch elements
  let tooltip;
  let tooltipNotch;

  // On resize make sure the class graph has the correct dimensions.
  window.onresize = () => {
    d3.select("#class-graph")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 " + width + " " + height);
    updateClassLegend();
    //drawBoundingBox();
  }

  function initTooltip() {
    tooltipNotch = d3.select("#graph-container").append("div")
      .attr("class", "tooltip-notch")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("width", "0.75rem")
      .style("height", "0.75rem")
      .style("background-color", "#212121")
      .style("transform", "rotate(45deg)")
      .style("color", "white");

    // Create a tooltip placeholder that will display the class title under
    // each node on mouseover.
    tooltip = d3.select("#graph-container").append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("max-width", "10rem")
      .style("display", "none")
      .style("border", "2px solid transparent")
      .style("border-radius", "5px")
      .style("padding", "5px")
      .style("text-align", "center")
      .style("user-select", "none")
      .style("pointer-events", "none")
      .style("position", "absolute")
      .style("background-color", "#212121")
      .style("color", "white");
  }

  function sortByMode(forceMode, xMargin, yMargin) {
    switch (forceMode) {
      case "number":
        return sortByNumber(xMargin, yMargin);
      case "unsorted":
        return sortByNone(xMargin, yMargin);
      case "major":
        return sortByMajor(xMargin, yMargin);
      default:
        console.log("unknown force mode");
    }
  }

  function sortByNone(xMargin, yMargin) {
    let forceRadius = height / 20;
    let forceX = (width / 2);
    let forceY = (height / 2);
    let forceStrength = 0.1;
    let chargeStrength = -100;
    let linkDistance = 60;
    let linkStrength = 0.9;
    return d3.forceSimulation(nodes)
      .force("radial", d3.forceRadial(forceRadius, forceX, forceY).strength(forceStrength))
      .force("link", d3.forceLink(links).distance(linkDistance).strength(linkStrength))
      .force("charge", d3.forceManyBody().strength(chargeStrength))
      .force("collide", d3.forceCollide().radius(classRadius))
  }

  function sortByNumber(xMargin, yMargin) {
    console.log(links)
    return d3.forceSimulation(nodes)
      .force("x", d3.forceX(d => {
        let num = d.number;
        return ((Math.floor(num / 100) - 1) * width / 5 + width / 10);
      }).strength(2))
      .force("y", d3.forceY(d => {
        let num = d.number;
        let numTitle = d.numTitle;
        let digitArray = numbers[Math.floor(num / 100)];
        return yMargin(digitArray.indexOf(numTitle) * height / digitArray.length +
               (height / digitArray.length / 1.5));
      }).strength(0.25))
      .force("link", d3.forceLink(links).distance(150).strength(0.25))
      .force("collide", d3.forceCollide().radius(classRadius));
  }

  function sortByMajor(xMargin, yMargin) {
    let CSDepts = majorCategories["CS"];
    let CHIDDepts = majorCategories["CHID"];
    return d3.forceSimulation(nodes)
      .force("charge", d3.forceManyBody().strength(-100))
      .force("collide", d3.forceCollide().radius(classRadius))
      .force("x", d3.forceX(d => {
        let dept = d.department;
        if (CSDepts.has(dept)) {
          return xMargin(4 * width / 20);
        } else if (CHIDDepts.has(dept)) {
          return xMargin(10 * width / 20);
        } else {
          return xMargin(16 * width / 20);
        }
      }).strength(0.25))
      .force("y", d3.forceY(yMargin(height / 2.5)).strength(0.25));
  }

  function updateClassGraph() {
    classRadius = style_by_mode[forceMode]["r"]
    height = style_by_mode[forceMode]["h"]
    width = style_by_mode[forceMode]["w"]
    const xMargin = linScale(0, width, margin.left, width - margin.right);
    const yMargin = linScale(height, 0, height - margin.bottom, margin.top);
    let sim = sortByMode(forceMode, xMargin, yMargin);
    classGraph(nodes, links, sim, xMargin, yMargin);
    updateClassLegend();
  }

  function linScale(ldomain, rdomain, lrange, rrange) {
    const scale = d3.scaleLinear()
      .domain([ldomain, rdomain])
      .range([lrange, rrange]);
    return scale;
  }

  function appendText(node, text, xPos, yPos, fontSize, fontWeight = "normal",
                      userSelect = "none", tclass = "") {
    node.append("text")
      .text(text)
      .attr("class", tclass)
      .attr("x", xPos + "px")
      .attr("y", yPos + "px")
      .style("fill", "black")
      .style("text-align", "center")
      .style("text-anchor", "middle")
      .style("font-size", fontSize)
      .style("font-weight", fontWeight)
      .style("user-select", userSelect);
  }

  function classGraph(nodes, links, forceSim, xMargin, yMargin) {
    id("class-graph").innerHTML = "";
    let svg = d3.select("#class-graph")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 " + width + " " + height);

    const [node, edge] = drawNodes(svg, nodes, links, forceSim);

    switch (forceMode) {
      case "number":
        // Append dashed vertical lines in number mode
        for (let i = 1; i < numbers.length - 1; i++) {
          svg.append("line")
            .style("stroke", "black")
            .style("stroke-dasharray", "5 ,5")
            .attr("x1", xMargin(i * width / 5))
            .attr("y1", 0)
            .attr("x2", xMargin(i * width / 5))
            .attr("y2", height);
        }
        // Append text for each nxx class number group
        for (let i = 1; i < numbers.length; i++) {
          let xPos = xMargin((i - 1) * width / 5 + 75);
          let yPos = margin.top / 2;
          let fontWeight = "bold";
          appendText(svg, i + "xx", xPos, yPos, "2rem", fontWeight);
        }
        break;

      case "major":
        // Append group titles above force centers
        let yPos = 50;
        let fontWeight = "bold";
        appendText(svg, "CS", xMargin(3.25 * width / 20), yPos, "2rem", fontWeight);
        appendText(svg, "CHID", xMargin(10.75 * width / 20), yPos, "2rem", fontWeight);
        appendText(svg, "Misc.", xMargin(16.75 * width / 20), yPos, "2rem", fontWeight);
        break;

      default:
        console.log("unknown force mode");
    }
    node.call(drag(forceSim, width, height));

    // update each node upon simulation tick
    forceSim.on("tick", () => {
      node
        .attr("transform", d => {
          let destX = clamp(d.x, classRadius + 5, width - classRadius - 5);
          let destY = clamp(d.y, classRadius + 5, height - classRadius - 5);
          return "translate(" + destX + ", " + destY + ")";
        });

      if (edge) {
        edge
        .attr("x1", d => {
          let sourceX = clamp(d.source.x, classRadius, width - classRadius);
          return sourceX + classRadius * Math.cos(edgeAngle(d));
        })
        .attr("y1", d => {
          let sourceY = clamp(d.source.y, classRadius, height - classRadius)
          return sourceY + classRadius * Math.sin(edgeAngle(d));
        })
        .attr("x2", d => {
          let targetX = clamp(d.target.x, classRadius, width - classRadius)
          return targetX - classRadius * Math.cos(edgeAngle(d));
        })
        .attr("y2", d => {
          let targetY = clamp(d.target.y, classRadius, height - classRadius)
          return targetY - classRadius * Math.sin(edgeAngle(d));
        });
      }
    });
  }

  function edgeAngle(d) {
    let sourceX = clamp(d.source.x, classRadius, width - classRadius)
    let sourceY = clamp(d.source.y, classRadius, height - classRadius)
    let targetX = clamp(d.target.x, classRadius, width - classRadius)
    let targetY = clamp(d.target.y, classRadius, height - classRadius)
    return Math.atan2(targetY - sourceY, targetX - sourceX)
  }

  function drag(simulation, width, height) {
    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      isDragging = true;
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
      d3.select(this.querySelector("circle")).attr("r", classRadius);
    }

    function dragged(event) {
      event.subject.fx = clamp(event.x, classRadius, width - classRadius);
      event.subject.fy = clamp(event.y, classRadius, height - classRadius);
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
      isDragging = false;
    }

    return d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  }

  function drawNodes(svg, node_data, edge_data) {
    // Draw directed edges if there are any edges and we're not sorting by major
    let edge = null;
    if (edge_data && forceMode !== "major") {
      // Draw arrowhead
      svg.append("svg:defs").append("svg:marker")
        .attr("id", "triangle")
        .attr("refX", 12)
        .attr("refY", 12)
        .attr("markerWidth", 50)
        .attr("markerHeight", 50)
        .attr("markerUnits","userSpaceOnUse")
        .attr("orient", "auto")
        .append("path")
        .attr("d", "M 0 4 6 12 0 20 13.5 12")
        .style("fill", "black");
      // Draw arrow path
      edge = svg.selectAll(".edge")
        .data(edge_data).enter()
        .append("line")
        .classed("edge", true)
        .attr("marker-end", "url(#triangle)")
        .style("stroke", "black")
        .style("stroke-width", 2)
        .style("opacity", 0.5);
    }

    // Create node group that we'll populate with the circle and text objects
    const node = svg.selectAll("g")
      .data(node_data).enter()
      .append("g");

    let deptScale = d3.scaleSequential().domain([1, 0]).interpolator(d3.interpolateSinebow);
    let numberScale = d3.scaleSequential().domain([
                      maxNum, minNum]).interpolator(d3.interpolateViridis);
    let gradeScale = d3.scaleSequential().domain([4.2, 3.3]).interpolator(d3.interpolateMagma);
    let creditsScale = d3.scaleSequential().domain([0, 15]).interpolator(d3.interpolateTurbo)

    /*numberScale = d3.scaleLinear()
      .domain([maxNum, minNum])
      .interpolate(d3.interpolateHcl)
      .range([d3.hcl("#51bd96"), d3.hcl("#660c21")])*/

    node.append("circle")
      .style("cursor", "pointer")
      .attr("r", classRadius)
      .attr("id", d => d.title)
      .attr("fill", d => {
        if (colorMode === "department") {
          return deptScale(departments.indexOf(d.department) / departments.length);
        } else if (colorMode === "number") {
          return numberScale(d.number);
        } else if (colorMode === "grade") {
          let grade = 0.0;
          if (d.grade === "CR") {
            grade = 4.0;
          } else if (d.grade === "NC") {
            grade = 0.0;
          } else {
            grade = parseFloat(d.grade);
          }
          return gradeScale(grade);
        } else if (colorMode === "credits") {
          return creditsScale(d.credits)
        }
      })
      .style("stroke-width", classRadius / 10)
      .style("stroke-opacity", 1.0)
      .style("fill-opacity", 0.4)
      .on("mouseover", d => {
        if (!isDragging) {
          d3.select(d.target).transition()
            .duration(100)
            .style("stroke", "black")
            .attr("r", 6 * classRadius / 5);
        }
      })
      .on("mousedown", d => {
        d3.select(d.target)
          .style("stroke", "none")
          .attr("r", classRadius);
      })
      .on("mouseout", d => {
        d3.select(d.target).transition()
          .duration(100)
          .style("stroke", "none")
          .attr("r", classRadius);
      });

    appendText(node, d => d.numTitle, 0, classRadius / 4, classRadius / 19 + "rem",
               "normal", "none", "class-label");
    bindTooltip(node, d => d.target.id, {"bottom": 11 * classRadius / 5, "left": 0});

    return [node, edge];
  }

  function bindTooltip(node, contentFunc, margin={"bottom": 5, "left": 0}) {
    node.on("mouseover", d => {
      if (!isDragging) {
        let nodePos = d.target.getBoundingClientRect();
        let bodyPos = document.body.getBoundingClientRect();
        tooltip
          .html(contentFunc(d))
          .style("opacity", 1)
          .style("display", "block")
          .style("top", nodePos.top - bodyPos.top + nodePos.height + margin.bottom + "px")

        let tooltipLeft = nodePos.left - tooltip.node().getBoundingClientRect().width / 2 +
                          nodePos.width / 2 + margin.left;
        tooltip.style("left", tooltipLeft + "px");

        tooltipNotch
          .style("opacity", 1)
          .style("top", nodePos.top - bodyPos.top + nodePos.height + margin.bottom -
                        tooltipNotch.node().getBoundingClientRect().height / 3 + "px")
          .style("left", tooltipLeft + tooltip.node().getBoundingClientRect().width / 2 -
                        tooltipNotch.node().getBoundingClientRect().width / 3 + "px");
      }
    })
    .on("mousedown", d => {
      tooltip.style("opacity", 0);
      tooltipNotch.style("opacity", 0);
    })
    .on("mouseout", d => {
      tooltip.style("opacity", 0);
      tooltipNotch.style("opacity", 0);
    });
  }

  // TODO: Write legends and their labels using margins instead of jankey hard-coding
  function updateClassLegend() {
    //console.log("updating legends")
    id("class-legend").innerHTML = "";
    let deptScale = d3.scaleSequential().domain([1, 0]).interpolator(d3.interpolateSinebow);
    let numberScale = d3.scaleSequential().domain([0, 600]).interpolator(d3.interpolateViridis);
    let gradeScale = d3.scaleSequential().domain([4.2, 3.3]).interpolator(d3.interpolateMagma);
    let creditsScale = d3.scaleSequential().domain([15, 0]).interpolator(d3.interpolateTurbo)
    let graphDims = id("class-graph").getBoundingClientRect();
    let w = graphDims.width;
    let lw = w / 5;
    let lh = graphDims.height;

    if (forceMode !== "major") {
      lh /= 1.5;
    }

    if (colorMode !== "department") {
      lw /= 1.75;
    }

    let legend = d3.select("#class-legend")
      .attr("width", w / 5)
      .attr("height", lh);

    if (colorMode === "department") {
      legend.selectAll("mydots")
        .data(departments)
        .enter()
        .append("circle")
          .attr("cx", lw / 20)
          .attr("cy", (d, i) => (i + 1) * lh / (departments.length + 1))
          .attr("r", lw / 20)
          .style("fill", d => deptScale(departments.indexOf(d) / departments.length))
          .style("opacity", 0.5);

      let labels = legend.selectAll("mylabels")
        .data(departments)
        .enter()
        .append("text")
          .attr("x", lw / 4)
          .attr("y", (d, i) => (i + 1) * lh / (departments.length + 1) + lw / 20)
          .text(d => d)
          .style("font-size", lw / 120 + "rem");

      bindTooltip(labels, d => deptAbvToName.get(d.target.innerHTML), {bottom: 35, left: 0});

    } else if (colorMode === "number") {
      let range = d3.range(0, 600, 1);
      let grad = legend.append("defs")
        .append("linearGradient")
        .attr("id", "grad")
        .attr("x1", "0%")
        .attr("x2", "0%")
        .attr("y1", "0%")
        .attr("y2", "100%")

      grad.selectAll("stop")
        .data(range)
        .enter()
        .append("stop")
          .attr("stop-color", d => numberScale(d))
          .attr("offset", (d, i) => i * (100 / range.length) + "%");

      legend.append("rect")
        .attr("x", 0)
        .attr("y", lh / 8)
        .attr("width", w / 30)
        .attr("height", 5 * lh / 6)
        .style("fill-opacity", 0.4)
        .style("fill", "url(#grad)");

      let scale = d3.scaleLinear()
        .domain([600, 0])
        .range([0, 5 * lh / 6]);

      legend.append("g")
        .attr("id", "legend-axis")
        .attr("transform", "translate(" + w / 30 + ", " + lh / 8 + ")")
        .call(d3.axisRight(scale)
          .ticks(6));

      let axisLabel = legend.append("text")
        .text("Class Number")
        .attr("transform", "rotate(90)")
        .attr("x", lh / 8 + 5 * lh / 20)
        .attr("y", -w / 8)
        .style("fill", "black")
        .style("text-align", "center")
        .style("font-size", lw / 50 + "rem");

      axisLabel.attr("x", lh / 2 - axisLabel.node().getBoundingClientRect().height / 2)

    } else if (colorMode === "grade") {
      let range = d3.range(4.0, 3.4, -0.1);
      range.pop()
      let bars = legend.selectAll(".bars")
        .data(range)
        .enter()
        .append("rect")
          .attr("class", "bars")
          .attr("x", 0)
          .attr("y", (d, i) => i * (5 * lh / 6 / range.length) + lh / 8)
          .attr("height", (5 * lh / 6) / range.length)
          .attr("width", w / 30)
          .style("fill", d => gradeScale(d))
          .style("fill-opacity", 0.4);

      let scale = d3.scaleLinear()
        .domain([4.0, 3.5])
        .range([(5 * lh / 6) / range.length / 2, (5 * lh / 6) - (5 * lh / 6) / range.length / 2]);

      legend.append("g")
        .attr("id", "legend-axis")
        .attr("transform", "translate(" + w / 30 + ", " + lh / 8 + ")")
        .call(d3.axisRight(scale)
          .ticks(5));

      legend.append("line")
        .attr("x1", w / 30)
        .attr("y1", lh / 8)
        .attr("x2", w / 30)
        .attr("y2", (5 * lh / 6) + lh / 8)
        .style("stroke", "black")
        .style("stroke-width", 1);

      d3.select("#legend-axis .domain").remove();

      let axisLabel = legend.append("text")
        .text("Grade (pt.)")
        .attr("transform", "rotate(90)")
        .attr("y", -w / 8)
        .style("fill", "black")
        .style("text-align", "center")
        .style("font-size", lw / 50 + "rem");

      axisLabel.attr("x", lh / 2 - axisLabel.node().getBoundingClientRect().height / 2)

    } else if (colorMode === "credits") {
      let range = d3.range(0, 15, 1);
      let grad = legend.append("defs")
        .append("linearGradient")
        .attr("id", "grad")
        .attr("x1", "0%")
        .attr("x2", "0%")
        .attr("y1", "0%")
        .attr("y2", "100%")

      grad.selectAll("stop")
        .data(range)
        .enter()
        .append("stop")
          .attr("stop-color", d => creditsScale(d))
          .attr("offset", (d, i) => i * (100 / range.length) + "%");

      legend.append("rect")
        .attr("x", 0)
        .attr("y", lh / 8)
        .attr("width", w / 30)
        .attr("height", 5 * lh / 6)
        .style("fill-opacity", 0.4)
        .style("fill", "url(#grad)");

      let scale = d3.scaleLinear()
        .domain([15, 0])
        .range([0, 5 * lh / 6]);

      legend.append("g")
        .attr("id", "legend-axis")
        .attr("transform", "translate(" + w / 30 + ", " + lh / 8 + ")")
        .call(d3.axisRight(scale)
          .ticks(6));

      let axisLabel = legend.append("text")
        .text("Class Credits")
        .attr("transform", "rotate(90)")
        .attr("x", lh / 8 + 5 * lh / 20)
        .attr("y", -w / 8)
        .style("fill", "black")
        .style("text-align", "center")
        .style("font-size", lw / 50 + "rem");

      axisLabel.attr("x", lh / 2 - axisLabel.node().getBoundingClientRect().height / 2)
    }
    d3.selectAll('#legend-axis .tick > text')
      .style("font-size", lw / 75 + "rem");
  }

  /* --------------------------- Helper Functions --------------------------- */

  function clamp(cur, min, max) {
    if (cur < min) {
      return min + 1;
    } else if (cur > max) {
      return max - 1;
    }
    return cur;
  }

  /**
   * Returns the element that has the ID attribute with the specified value.
   * @param {string} idName - element ID
   * @returns {object} DOM object associated with id (null if none).
   */
  function id(idName) {
    return document.getElementById(idName);
  }

  /**
   * Returns the array of elements that match the given CSS selector.
   * @param {string} selector - CSS query selector
   * @returns {object[]} array of DOM objects matching the query (empty if none).
   */
  function qsa(selector) {
    return document.querySelectorAll(selector);
  }

  /**
   * Returns the first element that matches the given CSS selector.
   * @param {string} selector - CSS query selector
   * @returns {object} the first DOM object matching the query (empty if none).
   */
  function qs(selector) {
    return document.querySelector(selector);
  }

  /**
   * Returns a new HTMLElement of the given type, but does not
   * insert it anywhere in the DOM.
   * @param {string} tagName - name of the typ of element to create
   * @returns {object} the newly-created HTML Element
   */
  function gen(tagName) {
    return document.createElement(tagName);
  }