import React, {memo, useState} from 'react';

function Socials() {
  const [showEmail, setShowEmail] = useState(false);

  return (
    <footer>
      <div id="socials-container" aria-label="Links to my socials" className="hidden">
        <span id="mail-tooltip" aria-label="email" className={(showEmail ? "" : "nodisplay")} tabIndex={0}>
          <p>knylundkai[at]gmail[dot]com</p>
          <div></div>
        </span>
        <i className='fa fa-envelope fa-2x' onClick={() => setShowEmail(!showEmail)}></i>
        <a href="https://github.com/KaiNylund" aria-label="github" target="_blank" rel="noreferrer">
          <i className='fa fa-github fa-2x'></i>
        </a>
        <a href="https://www.instagram.com/kai.nyl/" aria-label="instagram" target="_blank" rel="noreferrer">
          <i className='fa fa-instagram fa-2x'></i>
        </a>
      </div>
    </footer>
  );
}

export default memo(Socials);